.artist-preview {
  @apply min-w-140px w-140px;
  @apply bg-surface+3;
  @apply border rounded-8px border-surface-variant;
  @apply flex flex-col items-center justify-between;
  @apply p-16px;
  @apply transition-shadow;

  &__logo {
    @apply relative;
  }

  &__company-logo {
    @apply absolute z-10 -top-3px -left-3px;
    @apply h-28px w-28px p-2px;
    @apply rounded-1/2 bg-surface+3;
  }

  &__time {
    @apply font-inter text-caption-lg font-normal text-on-surface-variant text-center;
    span {
      @apply block;
    }

    &-value {
      @apply font-semibold text-on-surface whitespace-nowrap;
    }
  }

  &:hover {
    @apply cursor-pointer;
    @apply shadow-evaluation-1;
  }

  &:active {
    @apply shadow-transparent;
  }
}
